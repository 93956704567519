import React from "react";
import Footer from "../home/footer/Footer";
import Navbar from "../home/navbar/Navbar";
import Privacy from "./privacy";
import SEO from "../Seo";
import * as styles from "./privacy.module.css";

const Index = () => {
  return (
    <div className={styles.componentWrapper}>
      <SEO titleStub={`Privacy Policy`} urlStub={`/privacy-policy/`} />
      <Navbar />
      <Privacy />
      <Footer />
    </div>
  );
};

export default Index;

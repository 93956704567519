import React from "react";
import * as styles from "./privacy.module.css";

const Privacy = () => {
  return (
    <>
      <div className={styles.mainSection}>
        <div className={styles.contentWrap}>
          <div className={styles.content}>
            <h1>Privacy Policy</h1>
          </div>
        </div>
      </div>
      <div className={styles.wrapper}>
        <h2>Privacy Policy for sleeplessyogi.com</h2>
        <div className={styles.contentWrap}>
          <div className={styles.content}>
            <p>
              If you require any more information or have any questions about
              our privacy policy, please feel free to contact us by email at
              sleeplessyogi@gmail.com
            </p>
            <p>
              At sleeplessyogi.com, the privacy of our visitors is of extreme
              importance to us. This privacy policy document outlines the types
              of personal information is received and collected by
              sleeplessyogi.com and how it is used.
            </p>
            <p className="font-bold text-xl	">
              What do we use your information for?
            </p>
            <p>
              Any of the information we collect from you may be used in one of
              the following ways:
            </p>
            <ul>
              <li>
                To personalize your experience : (your information helps us to
                better respond to your individual needs)
              </li>
              <li>
                To improve our website : (we continually strive to improve our
                website offerings based on the information and feedback we
                receive from you)
              </li>
              <li>
                To improve customer service : (your information helps us to more
                effectively respond to your customer service requests and
                support needs)
              </li>
              <li>
                To send periodic emails : The email address you provide may be
                used to send you information, respond to inquiries, and/or other
                requests or questions.
              </li>
            </ul>
            <p className="font-bold text-xl	">
              How do we protect your information?
            </p>
            <p>
              We implement a variety of security measures to maintain the safety
              of your personal information when you enter, submit, or access
              your personal information.
            </p>
            <p className="font-bold text-xl	">Cookies and Web Beacons</p>
            <p>
              sleeplessyogi.com does use cookies to store information about
              visitors preferences, record user-specific information on which
              pages the user access or visit, customize Web page content based
              on visitors browser type or other information that the visitor
              sends via their browser.
            </p>
            <p className="font-bold text-xl	">Information We Collect</p>
            <p>
              The personal information that you are asked to provide, and the
              reasons why you are asked to provide it, will be made clear to you
              at the point we ask you to provide your personal information.
            </p>
            <p>
              If you contact us directly, we may receive additional information
              about you such as your name, email address, phone number, the
              contents of the message and/or attachments you may send us, and
              any other information you may choose to provide.
            </p>
            <p>
              When you register for an Account, we may ask for your contact
              information, including items such as name, company name, address,
              email address, and telephone number.
            </p>
            <p className="font-bold text-xl	">Cookies And Web Beacons</p>
            <p>
              Like any other website, sleeplessyogi uses ‘cookies’. These
              cookies are used to store information including visitors’
              preferences, and the pages on the website that the visitor
              accessed or visited. The information is used to optimize the
              users’ experience by customizing our web page content based on
              visitors’ browser type and/or other information.
            </p>
            <p>
              For more general information on cookies, please read{" "}
              <a
                href="https://www.generateprivacypolicy.com/#cookies"
                alt="Cookies article"
              >
                the Cookies article on Generate Privacy Policy website
              </a>
              .
            </p>
            <p className="font-bold text-xl	">Google DoubleClick DART Cookie</p>
            <p>
              Google is one of a third-party vendor on our site. It also uses
              cookies, known as DART cookies, to serve ads to our site visitors
              based upon their visit to www.website.com and other sites on the
              internet. However, visitors may choose to decline the use of DART
              cookies by visiting the Google ad and content network Privacy
              Policy at the following URL –
              https://policies.google.com/technologies/ads
            </p>
            <p className="font-bold text-xl	">Our Advertising Partners</p>
            <p>
              Some of advertisers on our site may use cookies and web beacons.
              Our advertising partners are listed below. Each of our advertising
              partners has their own Privacy Policy for their policies on user
              data. For easier access, we hyperlinked to their Privacy Policies
              below. Google – https://policies.google.com/technologies/ads
            </p>
            <p className="font-bold text-xl	">
              Advertising Partners Privacy Policies
            </p>
            <p>
              You may consult this list to find the Privacy Policy for each of
              the advertising partners of sleeplessyogi. Third-party ad servers
              or ad networks uses technologies like cookies, JavaScript, or Web
              Beacons that are used in their respective advertisements and links
              that appear on sleeplessyogi, which are sent directly to users’
              browser. They automatically receive your IP address when this
              occurs. These technologies are used to measure the effectiveness
              of their advertising campaigns and/or to personalize the
              advertising content that you see on websites that you visit. Note
              that sleeplessyogi has no access to or control over these cookies
              that are used by third-party advertisers.
            </p>
            <p className="font-bold text-xl	">Third Party Privacy Policies</p>
            <p>
              sleeplessyogi’s Privacy Policy does not apply to other advertisers
              or websites. Thus, we are advising you to consult the respective
              Privacy Policies of these third-party ad servers for more detailed
              information. It may include their practices and instructions about
              how to opt-out of certain options. You can choose to disable
              cookies through your individual browser options. To know more
              detailed information about cookie management with specific web
              browsers, it can be found at the browsers’ respective websites.
            </p>
            <p className="font-bold text-xl	">
              CCPA Privacy Rights (Do Not Sell My Personal Information)
            </p>
            <p>
              Under the CCPA, among other rights, California consumers have the
              right to: Request that a business that collects a consumer’s
              personal data disclose the categories and specific pieces of
              personal data that a business has collected about consumers.
              Request that a business delete any personal data about the
              consumer that a business has collected. Request that a business
              that sells a consumer’s personal data, not sell the consumer’s
              personal data. If you make a request, we have one month to respond
              to you. If you would like to exercise any of these rights, please
              contact us.
            </p>
            <p className="font-bold text-xl	">GDPR Data Protection Rights</p>
            <p>
              We would like to make sure you are fully aware of all of your data
              protection rights. Every user is entitled to the following: The
              right to access – You have the right to request copies of your
              personal data. We may charge you a small fee for this service. The
              right to rectification – You have the right to request that we
              correct any information you believe is inaccurate. You also have
              the right to request that we complete the information you believe
              is incomplete. The right to erasure – You have the right to
              request that we erase your personal data, under certain
              conditions. The right to restrict processing – You have the right
              to request that we restrict the processing of your personal data,
              under certain conditions. The right to object to processing – You
              have the right to object to our processing of your personal data,
              under certain conditions. The right to data portability – You have
              the right to request that we transfer the data that we have
              collected to another organization, or directly to you, under
              certain conditions. If you make a request, we have one month to
              respond to you. If you would like to exercise any of these rights,
              please contact us.
            </p>
            <p className="font-bold text-xl	">Children’s Information</p>
            <p>
              Another part of our priority is adding protection for children
              while using the internet. We encourage parents and guardians to
              observe, participate in, and/or monitor and guide their online
              activity. sleeplessyogi does not knowingly collect any Personal
              Identifiable Information from children under the age of 13. If you
              think that your child provided this kind of information on our
              website, we strongly encourage you to contact us immediately and
              we will do our best efforts to promptly remove such information
              from our records.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;
